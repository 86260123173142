<template>
  <div class="latest container screen-mt" :class="{'anim': visible && cnt === 1, 'hide': hide}">
    <div class="latest__inside">
      <p class="latest__title screen-title">Latest Innovations</p>
      <div class="divider"></div>
      <div class="latest__top flex justify-between items-end">
        <div class="flex text column onest">
          <p>As we bring you the latest innovations and trends in the IT sector.</p>
          <p>Our commitment to staying at the forefront of technology ensures that you are well-informed and equipped for the digital future. Explore most hyped technologies with us.</p>
        </div>
        <Button
          text="Connect with our Experts"
          iconName="more"
          color="white"
          @click="isModal = true"
        ></Button>
      </div>
      <div class="latest__content flex">
        <template v-for="(item, i) in items" :key="item">
          <div :id="`d${i+1}`" :class="{'hide': divBlocks[i].cnt == 0 && cnt === 1, 'anim': divBlocks[i].visible && divBlocks[i].cnt == 1 && cnt === 1}">
            <ItemsBlock :img="item.img" :title="item.title" :text="item.text"></ItemsBlock>
          </div>
        </template>
      </div>
    </div>
  </div>
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Button from "../common/Button.vue";
import ItemsBlock from "./ItemsBlock.vue";
import Modal from "../common/Modal.vue";
import { isElementVisible } from "@/hooks/visibility";
import useWindowWidth from "@/hooks/useWindowWidth";

const isModal = ref(false);
const props = defineProps({visible: Boolean, hide: Boolean, cnt: Number})
const items = ref([
  {
    img: require("@/assets/img/latest1.png"),
    title: "Artificial Intelligence",
    text: "Ater-Tech is at the forefront of integrating Artificial Intelligence (AI) into our IT solutions, harnessing its power to transform decision-making and operational efficiency.",
  },
  {
    img: require("@/assets/img/latest2.png"),
    title: "Machine Learning",
    text: "Our commitment to innovation is exemplified in our work with Machine Learning. At Ater-Tech, we develop systems that learn and improve from experience without being explicitly programmed.",
  },
  {
    img: require("@/assets/img/latest3.png"),
    title: "Blockchain Technology",
    text: "Blockchain technology represents a paradigm shift in how information is shared and secured, and at Ater-Tech, we're leveraging its potential beyond the realm of cryptocurrencies.",
  }
])
const windowWidth = useWindowWidth();
const divBlocks = ref(Array.from({ length: 3 }, () => ({ id: null, visible: false, cnt: 0 })));

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const handleScroll = debounce(() => {
  divBlocks.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (element) {
      const isVisible = windowWidth > 768 ? isElementVisible(element, 150) : isElementVisible(element, 100);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt++;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
}, 50);

onMounted(() => {
  divBlocks.value.forEach((block, index) => {
    block.id = `d${index + 1}`;
  });
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<style lang="scss" scoped>
.latest {
  &.hide {
    .screen-title, .text p:first-child  {
      opacity: 0;
    }
  }
  &.anim {
    .screen-title, .text p:first-child, .text p:nth-child(2), button {
      transform: translateY(10%);
      opacity: 0;
      animation: moveTop 1s ease forwards;
    }
    .text p:first-child {
      animation-delay: 0.5s;
    }
    .text p:nth-child(2) {
      animation-delay: 0.8s;
    }
    .text button {
      animation-delay: 2s;
    }
  }
  #d1, #d2, #d3 {
    width: calc(100% / 3);
    &.hide {
      opacity: 0;
    }
    &.anim {
      opacity: 0;
      transform: translateY(15%);
      animation: moveTop 1.5s ease forwards;
    }
  }
  #d2.anim {
    animation-delay: 0.3s;
  }
  #d3.anim {
    animation-delay: 0.5s;
  }
  &__inside {
    padding: 0 70px;
  }
  &__top {
    .text {
      width: 80%;
      max-width: 870px;
      p:first-child {
        color: $white;
        font-size: 40px;
        line-height: 56px; 
      }
      p:nth-child(2) {
        color: rgba(255, 255, 255, 0.80);
        font-size: 20px;
        line-height: 32px;
        margin-top: 24px;
      }
    }
  }
  &__content {
    gap: 30px;
    margin-top: 88px;
  }
}

@media (max-width: 1500px) {
  .latest {
    &__inside {
      padding: 0;
    }
    &__top {
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
    }
  }
}

@media (max-width: 1200px) {
  .latest {
    &__content {
      flex-direction: column;
      margin-top: 48px;
      gap: 16px;
    }
    #d1, #d2, #d3 {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .latest {
    &.anim {
      .latest__top {
        opacity: 0;
        animation: moveTop 1s ease forwards;
        transform: translateY(7%);
      }
    }
    &__top {
      gap: 24px;
      .text p:first-child, .text p:nth-child(2), button{
        opacity: 1 !important;
        transform: translateY(0) !important;
      }
      .text {
        width: 100%;
        p {
          &:first-child {
            font-size: 24px;
            line-height: 32px; 
          }
          &:nth-child(2) {
            font-size: 18px;
            line-height: 32px;
            margin-top: 20px;
          }
        }
      }
    }
    &__content {
      margin-top: 24px;
      gap: 8px;
    }
    button {
      width: 100%;
    }
  }
}
</style>