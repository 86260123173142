<template>
  <header>
    <div class="wrapper flex items-center justify-center">
      <Button v-if="windowWidth <= 768" iconName="chat" color="white" isSquare @click="isModal = true"></Button>
      <div class="logo">
        <RouterLink to="/" class="no-underline"><img src="@/assets/icons/logo.svg" alt="logo" /></RouterLink>
      </div>
      <nav v-if="windowWidth > 768"  class="flex items-center justify-center onest">
        <template v-for="(item, idx) in items" :key="item">
          <div 
            class="nav-item relative"
            :class="{'active': router.name === item.name, 'hovered': item.hovered}"
            @mouseenter="updateHovered(true, idx)" 
            @mouseleave="updateHovered(false, idx)"
          >
            <RouterLink :to="item.path">{{ item.title }}</RouterLink>
            <div class="router-circle absolute" :class="{'show': item.hovered && router.name !== item.name}"></div>
            <div class="router-circle active absolute" :class="{'show': router.name === item.name}"></div>
          </div>
        </template>
      </nav>
      <Button v-if="windowWidth > 768" iconName="chat" color="white" text="Connect" @click="isModal = true"></Button>
      <BurgerButton v-if="windowWidth <= 768"  @click="isMenuOpen = !isMenuOpen"></BurgerButton>
    </div>
  </header>
  <Menu :isOpen="isMenuOpen"></Menu>
  <Modal v-if="isModal" @close="isModal = false"></Modal>
</template>

<script setup>
import {ref, watch} from "vue";
import { useRoute } from "vue-router";
import Button from './Button.vue';
import BurgerButton from '../menu/BurgerButton.vue';
import Menu from "../menu/Menu.vue";
import Modal from "./Modal.vue";
import useWindowWidth from "@/hooks/useWindowWidth";
import navItems from "@/constants/nav";

const isModal = ref(false);
const items = ref(navItems);
const router = useRoute();
const windowWidth = useWindowWidth();

const isMenuOpen = ref(false);

const updateHovered = (value, idx) => {
  items.value[idx].hovered = value;
};

watch(items, (newNavItems, oldNavItems) => {
  items.value = newNavItems;
});

watch(isMenuOpen, () => {
  if(isMenuOpen.value) document.body.classList.add("no-scroll");
  else document.body.classList.remove("no-scroll");
})

watch(isModal, () => {
  if(isModal.value) document.body.classList.add("no-scroll");
  else if(!isMenuOpen.value && !isModal.value) document.body.classList.remove("no-scroll");
})
</script>

<style lang="scss" scoped>
header {
  padding: 0 58px;
  position: sticky;
  top: 0;
  height: 110px;
  background: $black;
  z-index: 10;
}
.wrapper {
  padding: 32px 0 29px;
  width: 100%;
  max-width: 1664px;
  margin: 0 auto;
  gap: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.logo {
  width: 159px;
  height: 18px;
  img {
    width: 100%;
    height: 100%;
  }
}

nav {
  flex: 1 0 auto;
  gap: 40px;
}

.nav-item {
  opacity: 0.8;
  transition: all 0.3s ease;

  a {
    color: $white;
    text-decoration: none;
    line-height: normal;
    &:focus,
    &:visited {
      color: $white;
    }
  }

  &:first-child {
    margin: 0 auto 0 4px;
  }

  &.active, &.hovered {
    opacity: 1;
  }
}

.router-circle {
  width: 6px;
  height: 6px;
  background: $white;
  opacity: 0.4;
  border-radius: 5px;
  bottom: -44px;
  left: 50%;
  transform: translate(-50%, 50%);
  visibility: hidden;
  transition: visibility 0.2s ease;
  &.active {
    width: 10px;
    height: 10px;
    opacity: 1;
    background: linear-gradient(90deg, #F00 0%, #FF3110 33%, #FF7024 78%, #FF892D 100%), #FFF;
  }
  &.show {
    visibility: visible;
  }
}

@media (max-width: 1023px) {
  header {
    padding: 0;
  }
  .wrapper {
    padding: 32px 36px 29px;
    gap: 28px;
  }

  nav {
    gap: 24px;
  }
}

@media (max-width: 768px) {
  header {
    height: 74px;
  }
  .wrapper {
    padding: 17px 20px 16px;
    justify-content: space-between;
  }

  .logo {
    width: 130px;
    height: 15px;
  }
}
</style>