const navItems = [
  {
    name: "home",
    path: "/",
    title: "Home",
    hovered: false
  },
  {
    name: "about",
    path: "/about",
    title: "About us",
    hovered: false
  },
  {
    name: "products",
    path: "/products",
    title: "Our Products",
    hovered: false
  },
  {
    name: "services",
    path: "/services",
    title: "Our Services",
    hovered: false
  }
]

export default navItems;