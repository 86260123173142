<template>
  <div class="hamburger relative flex column items-center justify-center" @click="isOpen = !isOpen" :class="{ 'is-open': isOpen }">
    <div class="dot-row flex" v-for="i in 2" :key="i">
      <div class="dot" :class="{ 'is-open': isOpen }" v-for="i in 2" :key="i"></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const isOpen = ref(false);
</script>

<style scoped lang="scss">
.hamburger {
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  gap: 4px;
  padding: 8px;
  border-radius: 12px;
  border: 1.176px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(14.117648124694824px);
}

.dot-row {
  gap: 4px;
  &:nth-child(1) {
    padding: 6px 6px 0;
  }
  &:nth-child(2) {
    padding: 0 6px 6px;
  }
}

.dot {
  width: 4px;
  height: 4px;
  background-color: $white;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.hamburger.is-open .dot-row {
  .dot {
    transition: all 0.4s ease;
  }
  &:nth-child(1) {
    .dot {
      &:nth-child(1) {
        transform: translate(4px, 4px);
      }
      &:nth-child(2) {
        transform: translate(-4px, 4px);
      }
    }
  }
  &:nth-child(2) {
    .dot {
      &:nth-child(1) {
        transform: translate(4px, -4px);
      }
      &:nth-child(2) {
        transform: translate(-4px, -4px);
      }
    }
  }
}

.hamburger.is-open .dot.is-open {
  opacity: 0;
}

.hamburger.is-open::before,
.hamburger.is-open::after {
  content: '';
  position: absolute;
  width: 40% !important;
  height: 2px;
  background-color: white;
  border-radius: 10px;
  transition: all 0.3s ease;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hamburger::before,
.hamburger::after {
  animation: openCross 0.5s ease forwards;
}

.hamburger.is-open::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.hamburger.is-open::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
</style>
