<template>
  <div class="home" :class="{'animate': headerCnt === 1}">
    <Header></Header>
    <div class="scroll-desk flex column items-center" v-if="windowWidth > 768">
      <div class="flex column circles">
        <div v-for="(i, id) in 7" :key="i" class="scroll-desk__circle" :class="{'active': inViewport === id}" @click="scrollTo(id)"></div>
      </div>
      <p class="onest">Scroll</p>
      <div class="scroll-desk__line"></div>
    </div>
    <div class="h_first-screen container" id="block1">
      <div v-if="windowWidth <= 768" class="h_first-screen__text gtwalsh flex wrap items-center justify-center">
        <span>Unleashing</span>
        <div class="figure relative">
          <img class="absolute" src="@/assets/img/home1.png" alt="screen star icon" />
        </div>
        <span>Innovation</span>
      </div>
      <video class="first-video" loop muted autoplay v-if="windowWidth > 768">
        <source src="@/assets/video/main_sphere.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <img v-else class="home-sphere" src="@/assets/img/sphere.png" alt="sphere"/>
      <div v-if="windowWidth > 768" class="h_first-screen__text gtwalsh flex items-center justify-center">
        <span>Unleashing</span>
        <div class="figure relative">
          <img class="absolute" src="@/assets/img/home1.png" alt="screen star icon" />
        </div>
        <span>Innovation</span>
      </div>
      <div class="h_first-screen__text gtwalsh flex wrap items-center justify-center">
        <span>Powering</span>
        <div class="flex items-end customized">
          <span>Solutions</span>
          <div class="figure relative">
            <img class="absolute" src="@/assets/img/home2.png" alt="screen star icon" />
          </div>
        </div>
      </div>
      <div class="scroll flex column" v-if="windowWidth < 769">
        <p class="onest">Scroll</p>
        <div class="scroll__line"></div>
      </div>
    </div>
   
    <SecondBlock id="block2" :visible="blocks[1].cnt == 1 && blocks[1].visible" :hide="blocks[1].cnt == 0" :key="blocks[1].cnt"></SecondBlock>
    <ScrollVideoBlocks id="block3"></ScrollVideoBlocks> 
    <ServicesBlock id="block4" :cnt="blocks[3].cnt" :visible="blocks[3].cnt == 1 && blocks[3].visible" :hide="blocks[3].cnt == 0" :key="blocks[3].cnt"></ServicesBlock>
    <div id="block5"><ProductsBlock :visible="blocks[4].cnt == 1 && blocks[4].visible" :hide="blocks[4].cnt == 0" :key="blocks[4].cnt"></ProductsBlock></div>
    <div id="block6"><LatestBlock :cnt="blocks[5].cnt" :visible="blocks[5].cnt == 1 && blocks[5].visible" :hide="blocks[5].cnt == 0" :key="blocks[5].cnt"></LatestBlock></div>

    <div class="container">
      <Footer id="block7" :anim="blocks[6].cnt == 1 && blocks[6].visible" :hide="blocks[6].cnt == 0" :key="blocks[6].cnt"></Footer>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Header from '@/components/common/Header.vue';
import ScrollVideoBlocks from '@/components/video/ScrollVideoBlocks.vue';
import SecondBlock from '@/components/block/SecondBlock.vue';
import ServicesBlock from '@/components/block/ServicesBlock.vue';
import ProductsBlock from '@/components/block/ProductsBlock.vue';
import LatestBlock from '@/components/block/LatestBlock.vue';
import Footer from '@/components/common/Footer.vue';
import { isElementVisible } from '@/hooks/visibility';
import useWindowWidth from '@/hooks/useWindowWidth';

const inViewport = ref(0);
const headerCnt = ref(0);
// animated blocks 
const blocks = ref(Array.from({ length: 9 }, () => ({ id: null, visible: false, cnt: 0 })));
const windowWidth = useWindowWidth();
const handleScroll = () => {
  headerCnt.value++;
  blocks.value.forEach((block, idx) => {
    const element = document.getElementById(block.id);
    if (element) {
      const isVisible = block.id === "block3" || block.id === "block4" || block.id === "block5" ? isElementVisible(element, 100) : isElementVisible(element, 300);
      if (isVisible && !block.visible) {
        inViewport.value = idx;
        block.visible = true;
        block.cnt++;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
};
// ----
const divRefs = ref([]);
const scrollTo = (id) => {
  const div = divRefs.value.find((ref) => ref.id === `block${id+1}`);
  if (div) {
    inViewport.value = id;
    div.scrollIntoView({ behavior: 'smooth' });
  }
};

onMounted(() => {
  blocks.value.forEach((block, index) => {
    block.id = `block${index + 1}`;
  });
  blocks.value.forEach((block, index) => {
    divRefs.value.push(document.getElementById(block.id));
  });
  handleScroll();
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
})
</script>

<style lang="scss">
.scroll-desk {
  position: fixed;
  left: 52px;
  top: 50%;
  z-index: 10;
  .circles {
    gap: 5px;
  }
  &__circle {
    border-radius: 5px;
    background: $white;
    width: 10px;
    height: 10px;
    &:hover {
      cursor: pointer;
    }
    &.active{
      background: linear-gradient(90deg, #F00 0%, #FF3110 33%, #FF7024 78%, #FF892D 100%), #FFF;
    }
  }
  p {
    color: $white;
    font-size: 12px;
    line-height: normal;
    transform: rotate(-90deg);
    margin: 40px 0 30px;
  }
  &__line {
    width: 1px;
    height: 50px;
    background: $white;
  }
}
.home.animate header {
  opacity: 0;
  transform: translateY(5%);
  animation: moveTop 1.2s ease forwards;
  animation-delay: 0.9s;
}

.first-video {
  width: 100%;
  max-width: 700px;
  margin: 10px auto 10px;
  display: block;
  opacity: 0;
  animation: show 2s ease forwards;
  animation-delay: 0.3s;
}

.h_first-screen {
  &__text {
    gap: 24px;
    margin: 0 auto;
    width: 100%;
    max-width: 887px;
    & + .h_first-screen__text {
      margin: 4px auto 250px;
      span, .figure {
        animation-delay: 1.5s;
      }
      .figure img {
        animation-delay: 2s;
      }
    }
    span, .figure {
      transform: translateY(10%);
      opacity: 0;
      animation: moveTop 1s ease forwards;
      animation-delay: 1.3s;
    }
    .customized {
      .figure {
        margin: 0 0 16px 25px;
      }
    }
  }
  .figure {
    img {
      transform: translate(-50%, 10%);
      opacity: 0;
      animation: moveImg 1s ease forwards;
      animation-delay: 1.8s;
    }
  }
  span {
    font-size: 76px;
    line-height: 100px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width: 1023px) {
  .h_first-screen {
    span {
      font-size: 45px;
      line-height: 72px;
    }
    .home-sphere {
      width: 90%;
      max-width: 252px;
      margin: 20px auto 0;
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .scroll-desk {
    display: none !important;
  }
  .home.animate header {
    opacity: 1 !important;
    transform: translateY(0) !important;
    animation: none !important;
  }
  .h_first-screen {
    .scroll {
      width: 1px;
      gap: 20px;
      margin: 90px auto 0;
      p {
        color: $white;
        font-size: 12px;
        line-height: normal;
        transform: rotate(-90deg);
      }
      &__line {
        width: 1px;
        height: 50px;
        background: $white;
      }
    }
    &__text:first-child {
      margin-top: 32px;
      
    }
    &__text {
      gap: 0;
      column-gap: 13px;
      span, .figure, .figure img {
        animation: none;
        opacity: 1;
        transform: translateY(0);
      }
      .figure img {
        transform: translate(-50%, 0);
      }
    }
    span {
      font-size: 40px;
      line-height: 60px; 
    }
  }
  .first-video {
    margin: 10px auto -40px;
    opacity: 1;
    animation: none;
  }
}
</style>