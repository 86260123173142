<template>
  <div class="h_third-screen container screen-mt flex column" @scroll="handleScroll">
    <template v-for="(item, idx) in videoBlocks" :key="item+idx">
      <VideoScroll
        :id="idx"
        :src="item.video"
        :title="item.title"
        :text1="item.text1"
        :text2="item.text2"
        :bg="item.img"
        :rotate="idx == 2"
        :object="item.object"
      ></VideoScroll>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import VideoScroll from './VideoScroll.vue';

const videoBlocks = ref([
  {
    img: "homeVideo1.png",
    title: "About Ater-Tech",
    text1: "Ater-Tech stands as a pivotal force in the IT industry, guiding a diverse portfolio of IT companies towards groundbreaking innovation and excellence. As a strategic holding company, our expertise lies in enhancing growth and fostering collaborative breakthroughs in the realm of technology.",
    text2: "We pride ourselves on the synergistic strength of our subsidiaries, each dedicated to delivering advanced and cutting-edge IT services.",
    video: require('@/assets/video/rings_comp.mp4'),
    object: require('@/assets/img/1.jpg')
  },
  {
    img: "homeVideo2.png",
    title: "Our Mission",
    text1: "Our mission at Ater-Tech is to drive innovation and empower businesses in the dynamic world of IT. We achieve this through strategic leadership and a commitment to excellence, ensuring that our array of IT companies thrives in complexity and contributes to our collective strength.",
    text2: "",
    video: require('@/assets/video/chips_comp.mp4'),
    object: require('@/assets/img/2.jpg')
  },
  {
    img: "homeVideo3.png",
    title: "Explore Our World",
    text1: "Discover the diverse and impactful solutions Ater-Tech offers, from bespoke software development to advanced cloud management. Every service is a testament to our dedication to quality, innovation, and customer-centric approach. Dive into the world of Ater-Tech, where we turn challenges into opportunities and ideas into realities.",
    text2: "",
    video: require('@/assets/video/dd_comp.mp4'),
    object: require('@/assets/img/3.jpg')
  }
])
const handleScroll = (event) => {
  event.stopPropagation();
};
</script>

<style lang="scss" scoped>
.h_third-screen {
  position: relative;
  background: $black;
}
</style>