<template>
  <div class="products container relative screen-mt" :class="{'anim': visible, 'hide': hide}">
    <img src="@/assets/img/user.png" class="products__img first absolute" alt="user photo" />
    <img src="@/assets/img/user2.png" class="products__img second absolute" alt="user photo" />
    <div class="products__content relative">
      <p class="gtwalsh screen-title">Our Products at Ater-Tech</p>
      <p class="onest">Discover the innovative software products at Ater-Tech, where we bring groundbreaking solutions to the forefront of technology. Our diverse suite of products includes Enhanced Client Account Solutions, designed for comprehensive account management, and our Advanced Automation System, which streamlines testing processes with efficiency.</p>
      <RouterLink to="/products" v-if="windowWidth > 768" class="no-underline prod-btn">
        <Button         
          iconName="more" 
          text="Discover more" 
          color="white"
        ></Button>
      </RouterLink>
    </div>
  </div>
  <RouterLink class="no-underline prod-btn" to="/products" v-if="windowWidth <= 768" :class="{'anim': visible, 'hide': hide}"><Button text="Learn more" color="white" iconName="more"></Button></RouterLink>
</template>

<script setup>
import { ref } from "vue";
import Button from '../common/Button.vue';
import useWindowWidth from '@/hooks/useWindowWidth';

const props = defineProps({visible: Boolean, hide: Boolean})
const windowWidth = useWindowWidth();
</script>

<style lang="scss" scoped>
.products {
  width: calc(100% - 176px);
  max-width: 1700px;
  padding: 155px 0 137px;
  background-image: url('../../assets/img/bg_basic_comp.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay, normal, overlay;
  backdrop-filter: blur(15px);
  border-radius: 24px;
  overflow: hidden;
  &.hide {
    .screen-title, p.onest, .prod-btn {
      opacity: 0;
    }
  }
  &.anim {
    .screen-title, p.onest, .prod-btn {
      opacity: 0;
      transform: translateY(10%);
      animation: moveTop 1s ease forwards;
    }
    p.onest {
      animation-delay: 0.5s;
    }
    .prod-btn {
      animation-delay: 0.8s;
    }
    .products__img {
      transform: translateY(10%);
      animation: moveTop 1.5s ease forwards;
    }
  }
  &__img {
    width: 500px;
    &.first {
      top: -135px;
      right: -100px;
    }
    &.second {
      bottom: -145px;
      left: -110px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
  &__content {
    z-index: 2;
    max-width: 740px;
    width: 100%;
    margin: 0 auto;
  }
  p.onest {
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    margin: 40px 0 64px;
  }
}
.prod-btn {
  display: block;
  button {
    margin: 0 auto;
  }
}
@media (max-width: 1600px) {
  .products {
    &__img {
      width: 400px;
      &.first {
        width: 350px;
        top: -85px;
        right: -70px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .products {
    padding: 100px 0;
    width: calc(100% - 80px);
    &__img {
      width: 300px;
      &.first {
        width: 300px;
        top: auto;
        bottom: -70px;
      }
      &.second {
        bottom: -70px;
      }
    }
    &__content {
      width: 80%;
      .screen-title {
        text-align: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .products {
    padding: 40px 0 65px;
    width: calc(100% - 40px);
    &.anim {
      opacity: 0;
      transform: translateY(7%);
      animation: moveTop 1.2s ease forwards;
      .screen-title, p.onest, .prod-btn {
        opacity: 1 !important;
        transform: translateY(0) !important;
        animation: none !important;
      }
    }
    &__img {
      width: 120px !important;
      &.first {
        width: 100px !important;
        right: -20px;
        bottom: -20px;
      }
      &.second {
        left: -55px;
        bottom: -45px;
      }
    }
    &__content {
      width: calc(100% - 40px);
      margin: 0 20px;
    }
    p.onest {
      margin: 16px 0 0;
    }
  }
  .prod-btn {
    width: calc(100% - 40px);
    button {
      width: 100%;
    }
    margin: 24px auto 0;
    &.hide {
      opacity: 0;
    }
    &.anim {
      opacity: 0;
      transform: translateY(30px);
      animation: moveTop 1.3s ease forwards;
    }
  }
}
</style>