<template>
  <div class="services container screen-mt" :class="{'anim': visible, 'hide': hide}">
    <div class="services__body">
      <p class="gtwalsh screen-title">Our Services at Ater-Tech</p>
      <div class="divider"></div>
      <p class="subtext onest">We orchestrate a comprehensive suite of IT solutions, ensuring excellence and innovation through our subsidiaries. Our expertise spans critical domains such as bespoke software development, including CRM and ERP solutions, and cutting-edge mobile application development. We also adeptly create impactful and responsive websites, ensuring the highest quality with our rigorous software testing services</p>
      <div class="services__blocks flex column">
        <div class="services__blocks__row" v-for="(i, idx) in blocks" :key="i">
          <div class="flex row">
            <template v-for="(item, index) in blocks[idx]" :key="index">
              <div :id="`dv${idx*blocks[idx].length+index+1}`" :class="{'hide': divItems[idx*blocks[idx].length+index].cnt == 0 && cnt == 1, 'anim': divItems[idx*blocks[idx].length+index].visible && divItems[idx*blocks[idx].length+index].cnt == 1 && cnt == 1}">
                <ImageBlock :title="item.title" :imgSrc="item.img"></ImageBlock>
              </div>
            </template>
          </div>
          <RouterLink
            to="/services"
            class="btn-link no-underline"
            v-if="(idx === 1 && windowWidth > 768) || (idx === 2 && windowWidth <= 768)"
            :class="{'hide': divItems[6].cnt == 0 && cnt == 1, 'anim': divItems[6].visible && divItems[6].cnt == 1 && cnt == 1}"
          >
            <Button
              id="dv7"
              iconName="more" 
              text="Get Started Today" 
              color="white"
              class="serv"
            ></Button>
          </RouterLink>
        </div>  
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import ImageBlock from './ImageBlock.vue';
import Button from "../common/Button.vue";
import useWindowWidth from "@/hooks/useWindowWidth";
import { isElementVisible } from "@/hooks/visibility";

const props = defineProps({visible: Boolean, hide: Boolean, cnt: Number})

const blocks = ref([
  [
    {
      title: "Software Development",
      img: require("@/assets/img/service1.png")
    },
    {
      title: "Mobile Apps Development",
      img: require("@/assets/img/service2.png")
    },
    {
      title: "Website Development",
      img: require("@/assets/img/service3.png")
    },
  ],
  [
  {
    title: "Software Testing Services",
    img: require("@/assets/img/service4.png")
  },
  {
    title: "UI/UX Design",
    img: require("@/assets/img/service5.png")
  },
  {
    title: "Cloud Management",
    img: require("@/assets/img/service6.png")
  },
  ]
])
const divItems = ref(Array.from({ length: 7 }, () => ({ id: null, visible: false, cnt: 0 })));

const windowWidth = useWindowWidth();
const isChanged = ref(false);

const changeArray = () => {
  if(windowWidth.value <= 768) {
    let val = blocks.value[0][2];
    let val2 = blocks.value[1].shift();
    let newVal = [val, val2];
    blocks.value[0].pop();
    blocks.value.splice(1, 0, newVal);
    isChanged.value = true;
  }
}
changeArray();
watch(windowWidth, () => {
  if(!isChanged.value) changeArray();
})

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const handleScroll = debounce(() => {
  divItems.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (element) {
      const isVisible = isElementVisible(element, 200);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt++;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
}, 50);

onMounted(() => {
  divItems.value.forEach((block, index) => {
    block.id = `dv${index + 1}`;
  });
  handleScroll();
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<style lang="scss" scoped>
.services {
  &.hide {
    opacity: 0;
  }
  &.anim {
    .screen-title {
      opacity: 0;
      transform: translateY(10%);
      animation: moveTop 1s ease forwards;
    }
    .subtext {
      opacity: 0;
      transform: translateY(10%);
      animation: moveTop 1.5s ease forwards;
      animation-delay: 1s;
    }
  }
  #dv1, #dv2, #dv3, #dv4, #dv5, #dv6 {
    max-width: calc(100% / 3);
    &.hide {
      opacity: 0;
    }
    &.anim {
      opacity: 0;
      transform: translateY(15%);
      animation: moveTop 1.5s ease forwards;
    }
  }
  #dv2.anim {
    animation-delay: 0.2s;
  }
  #dv3.anim {
    animation-delay: 0.3s;
  }
  #dv5.anim {
    animation-delay: 0.2s;
  }
  #dv6.anim {
    animation-delay: 0.3s;
  }
  .btn-link {
    display: block;
    &.hide {
      opacity: 0;
    }
    &.anim {
      opacity: 0;
      transform: translateY(15%);
      animation: moveTop 1.3s ease forwards;
      // animation-delay: 1s;
    }
  }
  &__body {
    padding: 0 58px;
  }
  .subtext {
    color: rgba(255, 255, 255, 0.80);
    font-size: 20px;
    line-height: 32px;
    max-width: 727px;
  }

  &__blocks {
    gap: 16px;
    margin: 56px 0 0;
    &__row {
      max-width: 100%;
      .row {
        gap: 16px;
        & + .btn-link {
          margin-top: 48px;
        }
      }
      & + .services__blocks__row {
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1023px) {
  .services__body {
    padding: 0;
  }
}

@media (max-width: 768px) {
  #dv1, #dv2, #dv3, #dv4, #dv5, #dv6 {
    width: 50%;
    max-width: calc(100% / 2) !important;
  }
  .services {
    &__blocks {
      margin-top: 24px;
      gap: 8px;
      &__row {
        width: 100%;
      }
      &__row .row {
        width: 100%;
        gap: 8px;
        align-items: stretch;
      }
      .btn-link {
        margin-top: 24px;
      }
      button {
        width: 100%;
      }
    }
  }
}
</style>