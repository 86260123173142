<template>
  <div class="video-block flex column" @scroll="handleScroll">
    <div class="video-block__main" :style="{ 'background-image': 'url(' + require(`@/assets/img/bg_basic_comp.jpg`) + ')' }">
      <div class="video-block__title flex items-center">
        <div class="figure relative">
          <img class="absolute" :src="require(`@/assets/img/${bg}`)" alt="block icon" />
        </div>
        <p class="gtwalsh screen-title">{{ title ?? '' }}</p>
      </div>
      <div class="video-block__text flex onest">
        <p>{{ text1 }}</p>
        <p v-if="text2 !== ''">{{ text2 }}</p>
      </div>
    </div>
    <div class="video-block__object" :class="{'full': id === 2}">
      <img :src="object" alt="block img" />
    </div>
    <!-- <ScrollyVideo :src="src" class="video_content" :class="{'rotate-block': rotate}" transitionSpeed=0.05 frameThreshold=1 full="false"></ScrollyVideo> -->
  </div>
</template>

<script setup>
// import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.vue';
const props = defineProps({
  src: String,
  id: Number,
  bg: String,
  title: String,
  text1: String,
  text2: String,
  rotate: Boolean,
  object: String
})

const handleScroll = (event) => {
  event.stopPropagation();
};
</script>

<style lang="scss" scoped>
// .video_content {
//   max-width: 46% !important;
//   max-height: 330px !important;
//   margin: -60px -70px 0 auto;
//   video, canvas {
//     min-width: 0 !important;
//     min-height: 0 !important;
//     width: 100% !important;
//     height: 100% !important;
//     display: block !important;
//   }
//   &.rotate-block {
//     transform: rotate(15deg);
//   }
// }
.video-block {
  border-radius: 33px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  overflow: hidden;
  background: $black;
  position: sticky;
  top: 100px;
  height: auto;
  &:nth-child(2) {
    top: 130px;
    z-index: 2;
  }
  &:nth-child(3) {
    top: 160px;
    z-index: 3;
  }
  &__main {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 0;
    background-blend-mode: overlay;
    backdrop-filter: blur(15px);
    padding: 96px 239px 56px 96px;
    position: relative;
    z-index: 99;
  }
  &__title {
    gap: 26px;
  }
  &__text {
    color: rgba(255, 255, 255, 0.80);
    font-size: 20px;
    line-height: 32px;
    gap: 82px;
    margin: 40px 0 0 169px;
    p {
      width: 45%;
    }
  }
  &__object {
    width: 500px;
    height: 285px;
    margin: -70px 10% 0 auto;
    img {
      width: 100%;
    }
    &.full {
      height: 430px;
    }
  }
  // video {
  //   // width: 800px;
  //   margin: -100px -100px 0 auto;
  // }
}

@media (max-width: 1600px) {
  .video-block {
    height: auto;
    &__main {
      padding: 56px;
    }
    &__text {
      gap: 28px;
      flex-direction: column;
      p {
        width: 80%;
      }
    }
    &__object {
      width: 400px;
      &.full {
        height: 350px;
      }
    }
  }
}

@media (max-width: 1350px) {
  .video-block {
    &__main {
      padding: 48px 32px;
    }
    &__text {
      margin: 32px 0 0 169px;
    }
  }
}

@media (max-width: 1023px) {
  .video-block {
    &__text {
      margin: 28px 0 0;
      gap: 20px;
      font-size: 18px;
      p {
        width: 100%;
      }
    }
    &__object {
      width: 300px;
      height: 200px;
      &.full {
        height: 260px;
      }
    }
  }
  // .video_content {
  //   width: 450px !important;
  //   height: 200px !important;
  //   margin: -50px 0 0 auto;
  // }
}

@media (max-width: 768px) {
  .video-block {
    top: 70px;
    &:nth-child(2) {
      top: 85px;
    }
    &:nth-child(3) {
      top: 100px;
    }
    &__main {
      padding: 34px 20px 20px;
    }
    .screen-title {
      font-size: 32px;
    }
    &__title {
      gap: 10px;
    }
    &__text {
      margin: 24px 0 0;
    }
    &__object {
      width: 70%;
      max-width: 260px;
      margin: 0 auto;
      height: auto !important;
    }
  }
  // .video_content {
  //   width: 350px !important;
  //   height: 200px !important;
  //   margin: -50px auto 0 auto;
  //   max-width: none !important;
  // }
}

@media (max-width: 500px) {
  .video-block {
    &__title {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>