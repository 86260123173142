<template>
  <div class="modal-wrap">
    <div class="modal">
      <div class="modal__body relative">
        <svg @click="emit('close')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="modal__content" v-if="!isSent">
          <p class="modal__title gtwalsh">Message Us</p>
          <form class="flex column modal-form" id="forma" @submit.prevent="send">
            <div class="modal-form__inputs flex column">
              <div class="inputs flex">
                <div class="input d-flex align-center" :class="{'error': isError && data.name.toString().trim().length == 0}">
                  <input placeholder="Name" type="text" @input="handleInput($event, 'name')" v-model="data.name" required aria-required="true" maxlength="100"/>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FFB2B2"/>
                  </svg>
                </div>
                <div class="input flex align-center" :class="{'error': isError && !emailRegex.test(data.email)}">
                  <input placeholder="Email" type="email" v-model="data.email" required maxlength="250"/>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FFB2B2"/>
                  </svg>
                </div>
              </div>
              <div class="input subject">
                <input placeholder="Subject" type="text" v-model="data.subject"/>
              </div>
              <textarea class="input textarea" placeholder="Message" v-model="data.message"></textarea>
            </div>
            
            <div class="flex column">
              <label class="custom-checkbox flex relative" for="ch-1" @click="isChecked = !isChecked" :key="isChecked" required>
                <input required type="checkbox" id="ch-1" :checked="isChecked"/>
                <span class="checkmark"></span>
                <span class="check-text">I have read and accept the <router-link to="/privacy">Privacy Policy</router-link>.</span>
              </label>
              <label class="custom-checkbox flex relative" for="ch-2" @click="isChecked2 = !isChecked2" :key="isChecked2">
                <input type="checkbox" id="ch-2" :checked="isChecked2"/>
                <span class="checkmark"></span>
                <span class="check-text">I want to receive notifications about the latest products, events and cutting-edge ideas.</span>
              </label>
            </div>
            <Button
              text="Get in touch with us"
              iconName="more"
              color="white"
              type="submit"
            ></Button>
          </form>
        </div>

        <div class="modal__content response flex column justify-center" v-else-if="isSent">
          <p class="modal__title gtwalsh">{{ isSuccess ? "Success" : "Error" }}</p>
          <img :src="require(`@/assets/img/${isSuccess ? 'success.png' : 'error.png'}`)" alt="modal response image" />
          <div class="onest">
            <template v-if="isSuccess"><p>Your application has been accepted</p><p>You will be contacted shortly for further details.</p></template>
            <p v-else>Something went wrong. Please try again!</p>
          </div>
          <Button v-if="isSuccess" text="Continue" color="white" @click="emit('close')"></Button>
          <div class="flex" v-else>
            <Button text="Close"></Button>
            <Button text="Try Again"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, watch, onMounted, onBeforeUnmount, onUnmounted} from "vue";
import Button from './Button.vue';

const emit = defineEmits(['close']);

const isError = ref(false);
const isChecked = ref(false);
const isChecked2 = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const isSent = ref(false);
const isSuccess = ref(false);

const data = ref({
  name: '',
  email: '',
  message: '',
  subject: ''
});

const validate = () => {
  let error = false;
  Object.entries(data.value).map(([key, value]) => {
    if(key == 'name' && value.toString().trim().length == 0) error = true;
    if(key === 'email' && !emailRegex.value.test(value)) error = true;
  });
  return error;
}
const handleInput = (event,propertyName) => {
  data.value[propertyName] = event.target.value;
  if(isError.value) validate();
}

const resetForm = () => {
  data.value = {
    name: "",
    email: "",
    subject: "",
    message: ""
  }
  isChecked.value = false;
  isChecked2.value = false;
  isSent.value = false;
}

const fetchData = (formdata) => {
  const request = {
    method: 'POST',
    body: formdata,
    mode: 'no-cors',
  }

  fetch('https://license.darmius.kz/mailsend', request)
  .then(response => {
    isSent.value = true;
    isSuccess.value = true;
    // resetForm();
  })
  .catch(e => {
    isSent.value = true;
    isSuccess.value = false;
    // resetForm();
  })
}

const send = () => {
  document.activeElement.blur();
  let form = document.getElementById('forma');
  
  isError.value = validate();
  form.reportValidity();
  if(!isError.value && isChecked.value) {
    const formdata = new FormData();
    formdata.append('id', 'Ater-Tech');
    formdata.append('name', 'User name: ' + data.value.name);
    formdata.append('email', 'User email: ' + data.value.email);
    formdata.append('emailto', "info@ater-tech.com");
    formdata.append('message', 'Message: ' + data.value.message);
    formdata.append('subject', 'Ater-Tech Request: ' + data.value.subject);

    fetchData(formdata)
  }
}

onUnmounted(() =>{
  resetForm();
  document.body.classList.remove("no-scroll");
})
</script>

<style lang="scss" scoped>
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.modal-wrap {
  background: rgba(0, 0, 0, 0.80);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.modal {
  width: 500px;
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 33px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background-color: #0A0F1B;
  // background-image: url('@/assets/img/modal_bg.png');
  // background-size: cover;
  &__body {
    padding: 32px;
    height: 100%;
    & > svg {
      position: absolute;
      right: 24px;
      top: 24px;
      &:hover {
        cursor: pointer;
        transition: all 0.3s ease;
        path {
          stroke-opacity: 1;
        }
      }
    }
  }
  &__title {
    color: $white;
    font-size: 38px;
    line-height: normal;
  }
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  &__content.response {
    .modal__title {
      text-align: center;
    }
    img {
      width: 200px;
      height: 200px;
      margin: 32px auto 8px;
    }
    div.onest p{
      color: $white;
      text-align: center;
      font-size: 16px;
      line-height: 26px;
    }
    div.onest {
      margin-bottom: 32px;
    }
    button {
      width: 100%;
    }
    .flex {
      gap: 8px;
      button {
        width: 50%;
      }
    }
  }
}

.custom-checkbox {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  position: relative;
  .check-text {
    opacity: 0.8;
    color: $white;
    font-family: 'Onest', sans-serif;
    font-size: 14px;
    line-height: 22px;
    a, a:visited, a:focus, a:active {
      color: $white;
    }
  }
  & span:nth-child(3) {
    padding-left: 12px;
  }  
  &:hover {
    cursor: pointer;
    .checkmark{
      border: 2px solid rgba(39, 39, 39, 0.40);
    }
  }
  & + .custom-checkbox {
    margin-top: 12px;
  }
  & span > span {
    text-decoration: underline;
  }
  .checkmark {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(13px);
    cursor: pointer;
    transition: border .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark {
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: #FF7024;
    backdrop-filter: blur(13px);
    background-size: 70% 70%;
    border-radius: 6px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/check.svg");
  }
  input[type="checkbox"]:checked + .checkmark::before {
    display: block;
  }
}
.inputs {
  gap: 4px;
  .input {
    width:50%;
  }
}

.modal-form {
  gap: 16px;
  margin-top: 32px;
  &__inputs {
    gap: 8px;
  }
  button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .modal {
    width: calc(100% - 40px);
    max-width: 500px;
    height: auto;
    &__body {
      padding: 32px 20px;
    }
    &__title {
      font-size: 32px;
    }
  }
  .inputs{
    flex-direction: column;
    gap: 8px;
    .input {
      width: 100%;
    }
  }
  .modal-form {
    margin-top: 20px;
  }
}
</style>