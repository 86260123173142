<template>
  <footer class="screen-mt">
    <div class="f-content">
      <p class="screen-title">Connect With Us</p>
      <div class="divider"></div>
      <div class="f-content__body flex column">
        <p class="gtwalsh">Complete our online form with your inquiry, and a representative will promptly respond.</p>
        <Button
          text="Connect with our Experts"
          iconName="more"
          color="white"
          @click="isModal = true"
        ></Button>
      </div>
      <div class="f-content__bottom flex justify-between items-end">
        <div class="empty"></div>
        <video loop muted autoplay v-if="windowWidth > 1023">
          <source src="@/assets/video/main_sphere.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
        <img class="sphere" v-else src="@/assets/img/sphere.png" alt="sphere" />
        <div class="info flex column gtwalsh items-end">
          <p>Iakovou Tompazi 1, Vashiotis Business Centre, 1st floor, Flat/Office 101, Neapoli, 3107, Limassol, Cyprus</p>
          <div class="flex justify-end">
            <a class="no-underline" href="mailto:info@ater-tech.com">info@ater-tech.com</a>
            <a class="underline" href="https://www.linkedin.com/company/ater-tech/" target="_blank">LinkedIn</a>
          </div>
          <div class="flex justify-end">
            <p>Ⓒ 2024, Ater-Tech Limited</p>
            <RouterLink to="/privacy" class="no-underline">Privacy policy</RouterLink>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="isModal" @close="isModal = false"></Modal>
  </footer>
</template>

<script setup>
import { ref } from "vue";
import Button from './Button.vue';
import Modal from './Modal.vue';
import useWindowWidth from "@/hooks/useWindowWidth";

const isModal = ref(false);
const windowWidth = useWindowWidth();
</script>

<style lang="scss" scoped>
footer {
  padding-bottom: 115px;
  .f-content {
    margin: 0 58px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    &__body {
      gap: 47px;
      & > p {
        color: $white;
        font-size: 40px;
        line-height: 56px;
        width: 100%;
        max-width: 900px;
      }
    }
    &__bottom {
      color: $white;
      margin-bottom: 56px;
      .empty {
        width: 280px;
      }
      video {
        width: 700px;
      }
    }
  }

  .info {
    width: 100%;
    max-width: 275px;
    text-align: right;
    gap: 40px;
    & > p {
      font-size: 18px;
      line-height: 28px; 
      opacity: 0.8;
      width: 90%;
    }
    div {
      &:nth-child(2) {
        gap: 24px;
        & a {
          font-size: 18px;
          line-height: 24px;
          opacity: 0.8; 
        }
      }
      &:nth-child(3) {
        gap: 20px;
        & a, p {
          font-size: 14px;
          line-height: 20px;
          opacity: 0.5;
        }
      }
    }
  }
}
a {
  color: $white;
  &:visited, &:focus, &:active {
    color: $white; 
  }
}

@media (max-width: 1400px) {
  footer .f-content {
    margin: 0;
    &__bottom {
      margin-top: 20px;
      video {
        width: 500px;
      }
      .empty {
        width: 80px;
      }
    }
  }
}

@media (max-width: 1200px) {
  footer {
    .f-content {
      &__bottom {
        flex-direction: column;
        align-items: flex-start;
      }
      video {
        margin: 0 auto;
      }
    }
    .info {
      text-align: left;
      align-items: flex-start;
      max-width: none;
    }
  }
}

@media (max-width: 768px) {
  footer {
    padding-bottom: 0;
    .sphere {
      width: 167px;
      margin: 45px auto 25px;
    }
    .f-content {
      &__body {
        gap: 24px;
        p {
          font-size: 24px;
          line-height: 32px;
        }
        button {
          width: 100%;
        }
      }
      &__bottom {
        margin-bottom: 20px;
        video {
          width: 280px;
        }
      }
    }
    .info {
      gap: 30px;
      & > p, & > div:nth-child(2) a  {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
</style>