<template>
  <div class="h_second-screen container" :class="{'anim': visible, 'hide': hide}">
    <p 
      class="h_second-screen__title screen-title"
      id="sb1"
    >Welcome to Ater-Tech —</p>
    <div class="divider"></div>
    <div class="h_second-screen__main flex" ref="anim2videoBlock">
      <ScrollyVideo class="video" :src="require('@/assets/video/logo_comp.mp4')" transitionSpeed=1.5></ScrollyVideo>
      <div class="text flex column">
        <p
          class="gtwalsh"
          id="sb2"
          :class="{'hide': divs[1].cnt == 0, 'anim': divs[1].visible && divs[1].cnt == 1}"
        >— a beacon of innovation in the realm of information technology.</p>
        <div>
          <p
            class="onest"
            id="sb3"
            :class="{'hide': divs[2].cnt == 0, 'anim': divs[2].visible && divs[2].cnt == 1}"
          >As a strategic force driving a diverse portfolio of IT companies, we are dedicated to pushing the boundaries of technology and business. Our role goes beyond managing; we are catalysts for innovation, collaboration, and growth in the ever-evolving digital landscape.</p>
          <p 
            class="onest"
            id="sb4"
            :class="{'hide': divs[3].cnt == 0, 'anim': divs[3].visible && divs[3].cnt == 1}"
          >Ater-Tech stands at the forefront of the IT industry, championing a new era of technological advancement and business acumen.</p>
        </div>
        <RouterLink to="/about" class="no-underline">
          <Button
            iconName="more" 
            text="Learn more" 
            color="white"
            id="sb5"
            :class="{'hide': divs[4].cnt == 0, 'anim': divs[4].visible && divs[4].cnt == 1}"
          ></Button>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import Button from '../common/Button.vue';
import { isElementVisible } from "@/hooks/visibility";
import useWindowWidth from "@/hooks/useWindowWidth";
import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.vue';

const windowWidth = useWindowWidth();

const props = defineProps({visible: Boolean, hide: Boolean})
const divs = ref(Array.from({ length: 5 }, () => ({ id: null, visible: false, cnt: 0 })));
const anim2video = ref(null);
const anim2videoBlock = ref(null);
function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const handleScroll = debounce(() => {
  divs.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (element) {
      const isVisible = isElementVisible(element, 100);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt++;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
}, 50);

onMounted(() => {
  divs.value.forEach((block, index) => {
    block.id = `sb${index + 1}`;
  });
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<style lang="scss">
.h_second-screen {
  overflow: hidden;
  &.hide {
    opacity: 0;
  }
  &.anim {
    .screen-title {
      opacity: 0;
      transform: translateY(10%);
      animation: moveTop 1s ease forwards;
    }
    .text {
      p, div, a, button  {
        &.anim {
          opacity: 0;
          transform: translateY(40px);
          animation: moveTop 1s ease forwards;
        }
        &.hide {
          opacity: 0;
        }
      }
    }
  }
  &__title {
    &.hide {
      opacity: 0;
    }
  }
  &__main {
    color: $white;
    .video {
      width: 40vw !important;
      max-width: 800px !important;
      height: 500px !important;
      margin-left: -80px;
    }
    .text {
      gap: 32px;
      max-width: 690px;
      width: 60%;
      margin-top: 96px;
      & > p {
        font-size: 40px;
        line-height: 52px;
      }
      & > div {
        color: rgba(255, 255, 255, 0.80);
        font-size: 20px;
        line-height: 32px;
        p {
          &:nth-child(2) {
            margin-top: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .h_second-screen {
    .video {
      height: 450px !important;
    }
    &__main {
      align-items: center;
      .text {
        margin-top: auto;
      }
    }
  }
}

@media (max-width: 1023px) {
  .h_second-screen {
    .video {
      width: 400px !important;
      height: 400px !important;
      margin-left: -40px;
    }
    &__main .text {
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .h_second-screen {
    margin-top: 80px !important;
    &__title {
      white-space: wrap;
      max-width: 300px;
    }
    &.anim {
      .h_second-screen__main {
        opacity: 0;
        transform: translateY(7%);
        animation: moveTop 1.2s ease forwards;
      }
    }
    &__main {
      flex-direction: column;
      .text {
        max-width: none;
        width: 100%;
        gap: 20px;
        p, div, a, button  {
          &.anim {
            opacity: 1 !important;
            transform: translateY(0) !important;
            animation: none !important;
          }
        }
        & > p {
          font-size: 24px;
          line-height: 32px; 
        }
        & > div {
          p {
            font-size: 18px;
            line-height: 32px;
            & + p {
              margin-top: 16px !important;
            }
          }
        }
        button {
          width: 100%;
          margin-top: 4px;
        }
      }
    }
    .video {
      margin: 0 auto 20px;
      width: 70% !important;
      height: 190px !important;
    }
  }
}
</style>